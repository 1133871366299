import React from "react";
import Chart from "chart.js/dist/chart.js";

class BarChart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      containerId: Math.random(),
      height: this.props.height || 500,
    };
    this.chartRef = React.createRef();
  }

  componentDidMount() {

    var myChart = new Chart(this.chartRef.current, {
    type: 'bar',
    data: {
        labels: this.props.labels,
            datasets: [{
                label: this.props.label,
                data: this.props.data,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)'
                ],
                borderColor: [
                    'rgba(255,99,132,1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)'
                ],
                borderWidth: 1
            }]
        },
        options: {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true
                    }
                }]
            }
        }
    });
  }

  render(){
    return(
        <div>
          <div
            id="#sparkline1"

            className='chart-overflow-top'></div>
            <canvas
              id={`chart_container_${this.state.containerId}`}
              ref={this.chartRef}
              width="400"
              height={this.state.height}
              >
            </canvas>
        </div>
      )
    }
}

export default BarChart;
