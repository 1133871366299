
import React from "react";
const L = window.L; // leaflet
class AreaMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      containerId: Math.random(),
      markers: this.props.markers || [],
      areas: this.props.areas || [],
      height: this.props.height || 1200,
    };
  }
  componentDidMount(){

    let origo = [59.911491, 10.757933]
    if(this.state.markers.length > 0){
      origo = this.state.markers[0].coordinates
    }
    const map = L.map(`map_container_${this.state.containerId}`);
    map.setView(origo, 11);

    let areas = this.state.areas;

    for(let j=0; j<areas.length;j++){
      let area = areas[j];
      if(area.coordinates.length > 0){
        let pointList = [];
        for(let i=0; i<area.coordinates.length; i++){
          let point = new L.LatLng(area.coordinates[i][1], area.coordinates[i][0]);
          pointList.push(point);
        }
        let polygon = L.polygon(pointList, {color: '#01D182'}).addTo(map);

        polygon.setStyle({fillColor: area.color, fillOpacity: area.opacity});

        const title = area.name + ' ' + area.text;
        polygon.bindTooltip(title,
          {permanent: true, direction:"center"}
        ).openTooltip()

        // zoom the map to the polygon
        map.fitBounds(polygon.getBounds());
      }
    }


    L.tileLayer('http://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=norges_grunnkart_graatone&zoom={z}&x={x}&y={y}', {
      attribution: '',
      maxZoom: 18,
    }).addTo(map);

    for(let i=0;i<this.state.markers.length;i+=1){
      let m = this.state.markers[i];
      let marker = L.marker(m.coordinates).addTo(map);
      marker.bindPopup(`<p>${m.title}</p><h3>${m.price}</h3><a target='_blank' href='https://www.finn.no/realestate/homes/ad.html?finnkode=${m.code}'><img src='${m.img_url}' width='300'/></a>`)
    }
  }

  render(){
    const mapStyles = {
      width: '100%',
      height: `${this.state.height}px`,
    };
    return(
      <div
        id={`map_container_${this.state.containerId}`}
        style={mapStyles}/>
    );
  }
}

export default AreaMap;
